import Main from './main_information';

function About() {
    return (
        <>
        <Main />
        </>
    );
}

export default About;