import React, { useEffect, useState } from 'react';
import '../css/styles.css';

function MedicPage() {
    const [isDisabled, setIsDisabled] = useState(false);

    const check_token_validity = async () => {
        const token = localStorage.getItem('RemindDoc_Tokenize');
        try {
            const response = await fetch(process.env.REACT_APP_CHECK_TOKEN, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: token }),
          });
          if (!response.ok) {
            window.location.href = process.env.REACT_APP_LOGIN_PAGE;
            return;
          }
          fetchMedications();
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
          window.location.href = process.env.REACT_APP_LOGIN_PAGE;
        }
      };

      const fetchMedications = async () => {
        const token = localStorage.getItem('RemindDoc_Tokenize');
        try {
            const response = await fetch(process.env.REACT_APP_GET_MEDIC, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: token }),
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          setMedications(data);
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
          window.location.href = process.env.REACT_APP_LOGIN_PAGE;
        }
      };

      const add_medic = async () => {
        const token = localStorage.getItem('RemindDoc_Tokenize');
        try {
            const response = await fetch(process.env.REACT_APP_ADD_MEDIC, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: token }),
          });
          if (!response.ok) {
            window.location.href = process.env.REACT_APP_LOGIN_PAGE;
          }
          check_token_validity();
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
          window.location.href = process.env.REACT_APP_LOGIN_PAGE;
        }
        setIsDisabled(true);
        setTimeout(() => {
        setIsDisabled(false);
        }, 2000);
      };
      
  const [medications, setMedications] = useState([]);

    useEffect(() => {
        check_token_validity();
    }, []);

  return (
    <>
      <br />
      <h2 className="text-center">Historique de médicament.</h2>
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <img className="img-fluid" src="https://cdn.pixabay.com/photo/2014/06/03/19/38/road-sign-361514_960_720.png" alt="Test" />
          </div>
          <div className="col-md-9">
            <p className="text-center">Ce logiciel est en version de test, des bugs peuvent apparaitre.<br />En cas de soucis, merci de nous reporter les problèmes rencontrés</p>
          </div>
        </div>
      </div>
      <br /><br />

      <h3 className="text-center">Prendre un médicament</h3>
      <center>
        <button type="button"  onClick={add_medic} disabled={isDisabled} className="button-take">Prendre un médicament</button>
      </center>

      <div className='container'>
        <div className='row'>
          {medications.map((medication, index) => (
            <div key={index} className='col-md-4'>
              <p className='text-center'>Médicament pris le : {new Date(medication.TIME).toLocaleString()}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default MedicPage;