import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar_Build from './components/navbar';
import HomePage from './components/homepage';
import About from './components/about';
import Footer from './components/footer';
import PageNotFound from './components/PageNotFound';
import RegisterPage from './components/register';
import MedicPage from './components/MedicPage';

function App() {
  return (
    <>
    <Router>
      <Navbar_Build/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/medic_status" element={<MedicPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
    <Footer/>
    </>
  );
}

export default App;