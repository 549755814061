function Footer () {
    return (
        <>
        <div className="footer">

        <p className="text-center Morph_Fc_Black">RemindDoc, Projet Etudiant de Julien Roy</p>
        </div>
        </>
    )
}

export default Footer;