import { Link } from 'react-router-dom';
import Main from './main_information';

function HomePage() {
    return (
      <>
        <Main />
        <div className="container homepage-container">
          <h2 className="text-center project-title font-title">
            La solution contre l'oublie de médicaments
          </h2>
          <div class="card-group">
            <div class="card card-space">
              <img
                src="/images/Smiling_Guy.png"
                class="card-img-top"
                alt="Smiling_Guy Holding a Phone, happy to see a good notification"
              />
              <div class="card-body">
                <p class="card-text">
                  Donnez le sourire à vos proches en les informant automatiquement que vous avez pris votre traitement.
                </p>
              </div>
            </div>
            <div class="card card-space">
              <img
                src="/images/Smiling_Guy.png"
                class="card-img-top"
                alt="..."
              />
              <div class="card-body">
              <p class="card-text">
                  Peut importe votre âge, notre solution est adaptée à tous, vous n'avez qu'à cliquer sur un bouton
                </p>
              </div>
            </div>
          </div>
          <h3 className="text-center font-title soluce-txt">
            Optez pour notre solution gratuite dès maintenant !
            </h3>
            <center>
                <br></br>
            <Link className="register-btn font-title Morph_White_50 Morph_Nav_Btn" to="/medic_status">Déja connecté ?</Link>
            <Link className="register-btn font-title Morph_White_50 Morph_Nav_Btn" to="/register">Je m'inscris maintenant</Link>
            <br></br>
          </center>
          <br></br>
        </div>
      </>
    );
  }
  
  export default HomePage;
  