import Main from "./main_information";

function loginSubmit(event) {
   event.preventDefault();

   const email = document.getElementById("EmailLogin").value;
   const password = document.getElementById("PasswordLogin").value;

   fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
      method: "POST",
      headers: {
         "Content-Type": "application/json",
      },
      body: JSON.stringify({ Email: email, Password: password }),
   })
      .then((response) => {
         console.log("Response:", response);
         if (!response.ok) {
            document.getElementById("login-result").innerText =
               "Email ou mot de passe incorrect. Veuillez réessayer.";
               localStorage.setItem("RemindDoc_Tokenize", "UNKNOWN");
         } else {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
               return response.json().then((data) => {
                  if (data.token) {
                     document.getElementById("login-result").innerText =
                        "Connection OK. Redirection en cours...";
                     localStorage.setItem("RemindDoc_Tokenize", data.token);
                     window.location.href = `${process.env.REACT_APP_LOGIN_REDIRECT}`;
                  }
                  return data;
               });
            }
         }
      })
      .catch((error) => {
         console.error("Error:", error);
         console.log("Login failed: " + error.message);
      });
}

function RegisterPage() {
   return (
      <>
         <Main />

         <br></br>
         <br></br>
         <h3 className="text-center font-title">Inscription | Connexion</h3>
         <form>
            <div className="container">
               <div className="row">
                  <div className="col-md">
                     <form>
                        <label for="Email" className="form-label">
                           Adresse Email
                        </label>
                        <input
                           type="email"
                           className="form-control"
                           id="EmailRegister"
                           aria-describedby="emailHelp"
                        />

                        <label for="password" className="form-label">
                           Mot de passe
                        </label>
                        <input
                           type="password"
                           className="form-control"
                           id="PasswordRegister"
                           aria-describedby="passwordHelp"
                        />

                        <label for="password" className="form-label">
                           Mot de passe (Confirmer)
                        </label>
                        <input
                           type="password"
                           className="form-control"
                           id="PasswordRegister2"
                           aria-describedby="passwordHelp"
                        />
                        <br></br>
                        <center>
                           <input
                              type="submit"
                              className="btn btn-success"
                              value="S'inscrire"
                           />
                        </center>
                        <br></br>
                        <br></br>
                        <br></br>
                     </form>
                  </div>

                  <div className="col-md">
                     <form>
                        <label htmlFor="Email" className="form-label">
                           Adresse Email
                        </label>
                        <input
                           type="email"
                           className="form-control"
                           id="EmailLogin"
                           aria-describedby="emailHelp"
                        />

                        <label htmlFor="password" className="form-label">
                           Password
                        </label>
                        <input
                           type="password"
                           className="form-control"
                           id="PasswordLogin"
                           aria-describedby="passwordHelp"
                        />

                        <br></br>
                        <center>
                           <p id="login-result" className="text-center"></p>
                           <button
                              type="button"
                              onClick={loginSubmit}
                              className="btn btn-success"
                           >
                              Me connecter
                           </button>
                        </center>
                     </form>
                     <br></br>
                     <br></br>
                  </div>
               </div>
            </div>
         </form>
      </>
   );
}

export default RegisterPage;
