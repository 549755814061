import React from 'react';

function PageNotFound() {
    return (
        <div style={{
            minHeight: '100vh', 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            backgroundColor: '#f5f5f5',
            flexDirection: 'column'
        }}>
            <h1 style={{color: '#444', fontSize: '3rem'}}>404</h1>
            <p style={{color: '#666', fontSize: '1.5rem'}}>Page Not Found</p>
        </div>
    );
}

export default PageNotFound;