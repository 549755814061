import { Link } from "react-router-dom";

function Main() {
    return (
        <div className="container-fluid M-div background_green">
            <br></br>
          <h1 className="text-center font-title">
            RemindDoc : La solution à l'oublie médicamenteux
          </h1>
          <br></br>
          <div className="container">
            <div className="row">
              <div className="col-sm-4 mb-3 mb-sm-0">
                <div className="card Morph_White_50 home-card mb-3">
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      Pourquoi utiliser Remindoc ?
                    </h5>
                    <p className="card-text">
                      Some quick example text to build on the card title and make
                      up the bulk of the card's contentSome quick example text to
                      build on the card title and make up the bulk of the card's
                      Some quick example text to build on the card title and make
                      up the bulk of the card's contentSome quick example text to
                      build on the card title and make up the bulk of the card's
                      contentSome quick example text to build on the card title
                      and make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="card Morph_White_50 home-card mb-3">
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      Quelles sont nos offres ?
                    </h5>
                    <p className="card-text">
                        Some quick example text to build on the card title and make
                        up the bulk of the card's contentSome quick example text to
                        build on the card title and make up the bulk of the card's
                        Some quick example text to build on the card title and make
                        up the bulk of the card's contentSome quick example text to
                        build on the card title and make up the bulk of the card's
                        contentSome quick example text to build on the card title
                        and make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="card Morph_White_50 home-card mb-3">
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      Quelles sont nos offres ?
                    </h5>
                    <p className="card-text">
                        Some quick example text to build on the card title and make
                        up the bulk of the card's contentSome quick example text to
                        build on the card title and make up the bulk of the card's
                        Some quick example text to build on the card title and make
                        up the bulk of the card's contentSome quick example text to
                        build on the card title and make up the bulk of the card's
                        contentSome quick example text to build on the card title
                        and make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <center>
            <Link className="about-button font-title Morph_White_50 Morph_Nav_Btn" to="/about">En savoir plus</Link>
          </center>
          <br></br>
        </div>
    );
}

export default Main;